import React from "react";
import "./portfolio.css";
import IMG1 from '../../assets/authentication.png'
// import IMG2 from '../../assets/A1.jpg'

const data=[
  {
    id:1,
    image:IMG1,
    title:"Authentication",
    github:"https://github.com/adaikkalam-1/Authentication-project",
    demo:"https://github.com"

  },
  {
    id:2,
    image:IMG1,
    title:"Ecommerce",
    github:"https://github.com/adaikkalam-1/Authentication-project",
    demo:"https://github.com"

  },
  {
    id:3,
    image:IMG1,
    title:"Authentication",
    github:"https://github.com/adaikkalam-1/Authentication-project",
    demo:"https://github.com"

  },
  {
    id:4,
    image:IMG1,
    title:"Authentication",
    github:"https://github.com/adaikkalam-1/Authentication-project",
    demo:"https://github.com"

  },
  {
    id:5,
    image:IMG1,
    title:"Authentication",
    github:"https://github.com/adaikkalam-1/Authentication-project",
    demo:"https://github.com"

  },
  {
    id:6,
    image:IMG1,
    title:"Authentication",
    github:"https://github.com/adaikkalam-1/Authentication-project",
    demo:"https://github.com"

  }
]
const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work </h5>
      <h2>My Projects</h2>
      <div className="container portfolio_container">
        {
          
          data.map(({id,image,title,github,demo}) => {
            return(
            <article key={id} className="portfolio_item">
          <div className="portfolio_item-image">
          <img src={image} alt={title} />
          </div>
          <h3>{title} </h3>
          <div className="portfolio_item-cta">
            <a href={github} className="btn" target="_blank">
              Github
            </a>
            <a href={demo} className="btn btn-primary" target="_blank">
              Live Demo
            </a>
          </div>
        </article>
          )
          })
        }
        
      </div>
    </section>
  );
};

export default Portfolio;

