import React from 'react'
import './contact.css'
import { MdOutlineEmail } from "react-icons/md";
import { FaFacebookMessenger,FaWhatsapp  } from "react-icons/fa";
import { useRef } from 'react';
import emailjs from 'emailjs-com'
const Contact = () => {
  const form =useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_kvyovcl', 'template_gkkqtiq', form.current, 'Lxm-rq8Zod97nBNJr')
     e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get In Touch </h5>
      <h2>Contact Me</h2>
      <div className="container contact_container">
        <div className="contact_options">
          <article className='contact_option'>
          <MdOutlineEmail className='contact_option-icon'/>
            <h4>Email</h4>
            <h5>adaikkalarajaa@gmail.com</h5>
            <a href="mailto:adaikkalarajaa@gmail.com" target="_blank">Send a message</a>
          </article>
          <article className='contact_option'>
          <FaFacebookMessenger className='contact_option-icon' />
            <h4>Messenger</h4>
            <h5>Adaikkalam</h5>
            <a href="https://m.me/adaikkalaraja.adaikkalaraja.796" target="_blank">Send a message</a>
          </article>
          <article className='contact_option'>
          <FaWhatsapp className='contact_option-icon'/>
            <h4>WhatsApp</h4>
            <h5>+91 7604894395</h5>
            <a href="https://wa.me/7604894395" target="_blank">Send a message</a>
          </article>
        </div>
        {/* ============END OF CONTACT OPTIONS============== */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type='email' name='email' placeholder='Your Email' required/>
          <textarea name="message"   rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact