import React from "react";
import "./about.css";
import { FiAward, FiUsers } from "react-icons/fi";
import { FaRegFolder } from "react-icons/fa";
import ME from "../../assets/A1.jpg";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FiAward className="about_icon" />
              <h5>Fresher</h5>
              <small>1 year prepared</small>
            </article>
            <article className="about_card">
              <FaRegFolder className="about_icon" />
              <h5>Projects</h5>
              <small>10+ Completed</small>
            </article>
          </div>
          <p>
          Hi! i'm Adaikkalam , and I am  BCA graduate with a passion for developing scalable web applications and working across the full stack.
          i am looking for a job to continue my growth and skills.
  
          </p>
          <a href="#contact" className="btn btn-primary">Let 's Talk</a>
        </div>
      </div>
    </section>
  );
};

export default About;
