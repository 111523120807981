import React from 'react'
import { FaLinkedin,FaGithub } from "react-icons/fa";

const HeaderSocials = () => {
  return (
    <div className='header_socials'>

        <a href='https://www.linkedin.com/in/adaikkalam-a-b02255236?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app' target='_blank'><FaLinkedin /></a>
        <a href='https://github.com/adaikkalam-1' target='_blank'><FaGithub /></a>
        {/* <a href='https://dribbble.com' target='_blank'><FaDribbble /></a> */}
    </div>
  )
}

export default HeaderSocials